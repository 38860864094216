import {useEffect, useRef, useState} from 'react';

interface UseActivityMonitorProps {
  activityCount: number;
}

export const useActivityMonitor = (refreshTimeMin: number): UseActivityMonitorProps => {
  const [activityCount, setActivityCount] = useState<number>(0);
  const activityDetected = useRef<boolean>(false);

  useEffect(() => {
    const handleActivity = () => activityDetected.current = true;
    
    const events: string[] = ['click', 'scroll', 'keydown'];

    events.forEach(event => window.addEventListener(event, handleActivity));

    const intervalId: NodeJS.Timeout = setInterval(() => {
      if (activityDetected.current) {
        setActivityCount(prevCount => prevCount + 1);
        activityDetected.current = false; 
      }
    }, refreshTimeMin * 60 * 1000);

    return () => {
      events.forEach(event => window.removeEventListener(event, handleActivity));
      clearInterval(intervalId);
    };
  }, [refreshTimeMin]);

  return {activityCount};
};
