import './Framing.scss';
/* eslint-disable react-hooks/exhaustive-deps */

import {useEffect, useRef, useState} from 'react';
import axios from 'axios';
import {useSelector} from "react-redux";
import { v4 as uuidv4 } from 'uuid';
import {fabric} from 'fabric';
import {useSnackbar} from 'notistack';
import {Button, Checkbox, FormControlLabel, IconButton, Tooltip} from '@mui/material';
import {KeyboardArrowLeft} from '@mui/icons-material';
import {
    API_FRAMES_PER_PAGE_PER_LEAFLET,
    API_GET_NEXT_PAGE_META,
    API_GET_NEXT_PAGE_META_OF_LEAFLET,
    API_PAGE_IMAGE,
    API_SKIP_PAGE
} from 'config/api/constants';
import {paths} from 'paths';
import {
    basicFramePayloadObject,
    frameProposal,
    frameProposalsResponseModel,
    framesPerPagePerLeafletPayloadModel
} from 'shared/models/frame.model';
import {pageImageAddress} from 'shared/models/page.model';
import {IRootState} from 'shared/reducers';
import {getURLParam} from 'utils/routing';
import {useFormatMessage} from 'utils/translate';
import {getFromLocalStorage} from 'utils/storageUtils';
import {lockLevels, lockSetter, releasePageLock, releasePageLockTypes} from 'utils/lockUtils';
import {LoadingOverlay} from 'components/LoadingOverlay/LoadingOverlay';
import ButtonClose from 'components/Buttons/ButtonClose';
import ColorPicker, {
    defaultFrameFillColor,
    frameFillLocalStorageKey,
    getComplementaryColor
} from 'components/gfx/ColorPicker/ColorPicker';
import {buildImageObject, frameTooSmall, getControls, MIN_FRAME_HEIGHT, MIN_FRAME_WIDTH} from 'components/gfx/utils';
import {ConfirmDialog} from 'components/ConfirmDialog';
import CountrySelectorConnected from 'components/Selectors/CountrySelector/CountrySelectorConnected';
import {inputSize} from 'components/Selectors/CountrySelector/CountrySelector';
import LeafletBrowserDrawer from './LeafletBrowserDrawer/LeafletBrowserDrawer';
import DetailsDrawer from './DetailsDrawer/DetailsDrawer';
import { NavigationMenuButton } from 'modules/NavigationMenu';

const Framing = ({history}) => {
    const translate = useFormatMessage();
    const { enqueueSnackbar } = useSnackbar();
    const imgRef = useRef<HTMLImageElement>();
    const [canvasReady, setCanvasReady] = useState<boolean>(false);
    const [loadingOverlayVisible, setLoadingOverlayVisible] = useState<boolean>(false);
    const [pageImageAddress, setPageImageAddress] = useState<pageImageAddress>(null);
    const [pageId, setPageId] = useState<string>(null);
    const canvasRef = useRef(null);
    const [canvas, setCanvas] = useState(null);
    const [counter, setCounter] = useState<number>(0);
    const [frameProposals, setFrameProposals] = useState<frameProposal[]>([]);

    const [confirmDialogOpen, setConfirmDialogOpen] = useState<boolean>(false);

    const [frameFill, setFrameFill] = useState<string>(getFromLocalStorage(frameFillLocalStorageKey) || defaultFrameFillColor);
    const colorPicker = useRef<string>(frameFill);
    const complementaryColor = useRef<string>(getComplementaryColor(frameFill));
    const [countryChangeAllowed, setCountryChangeAllowed] = useState<boolean>(false);
    const [isLeafletBrowserDrawerOpen, setIsLeafletBrowserDrawerOpen] = useState<boolean>(false);
    const [isDetailsBrowserDrawerOpen, setIsDetailsBrowserDrawerOpen] = useState<boolean>(false);
    const [currentSelectedFrame, setCurrentSelectedFrame] = useState<string>(null);
    const [currentSelectedFrameValidF, setCurrentSelectedFrameValidF] = useState<string>(null);
    const [currentSelectedFrameValidT, setCurrentSelectedFrameValidT] = useState<string>(null);

    const countryMarket = useSelector((state: IRootState) => state.userProfile.countryMarket);

    const cleanup = () => canvas.clear();

    const getNextPage = (leafletId: string = null) => {
        setFrameProposals([]);
        setLoadingOverlayVisible(true);
        const url: string = leafletId ? API_GET_NEXT_PAGE_META_OF_LEAFLET(leafletId) : API_GET_NEXT_PAGE_META(countryMarket.preferredCountry);
        lockSetter(lockLevels.page);
        axios.get(url).then((resp) => {
            if (resp.data) {
                const response: frameProposalsResponseModel = resp.data;
                const { leafletId, pageId, pageNumber, frameProposals } = response;
                if (frameProposals && Array.isArray(frameProposals)) {
                    setFrameProposals(frameProposals);//here
                }
                setPageId(pageId);
                setPageImageAddress({leafletId, pageNumber});
            } else {
                enqueueSnackbar(translate({id: 'frame.noPages'}), {variant: 'info'});
                const from: string = getURLParam('from');
                if (from){
                    history.push(from)
                }else history.push(paths.home);
            }
        }).catch((e) => {
            console.log(e)
        }).finally(() => setLoadingOverlayVisible(false));
    };

    const drawFrameProposals = () => {
        frameProposals.forEach((frame) => {
            const uniqueKey: string = uuidv4();
            if (doesFrameProposalFit(frame)) {
                const rect = new fabric.Rect({
                    left: frame.x1,
                    top: frame.y1,
                    fill: colorPicker.current,
                    stroke: complementaryColor.current,
                    strokeWidth: 1,
                    width: frame.x2 - frame.x1,
                    height: frame.y2 - frame.y1
                });
                rect.uuid = uniqueKey;
                rect.validFrom = null;
                rect.validTo = null;
                rect.controls = getControls();
                canvas.add(rect);
            } else {
                console.error(`frame proposal does not fit canvas ${canvas.width}x${canvas.height}`);
                console.error(frame);
            }
        });
    };

    const doesFrameProposalFit = (frame: frameProposal): boolean => {
        if (frame.x1 < 0 || frame.x2 < 0 || frame.y1 < 0 || frame.y2 < 0) {
            return false;
        }
        const {width, height} = canvas;
        if (frame.x1 > width || frame.x2 > width || frame.y1 > height || frame.y2 > height) {
            return false
        }
        return true;
    }

    const getPageImage = () => {
        const {leafletId, pageNumber} = pageImageAddress;
        cleanup();
        setLoadingOverlayVisible(true);

        axios.get(API_PAGE_IMAGE(leafletId, pageNumber), { responseType: 'blob' }).then((resp) => {
            if (resp.data) {
                imgRef.current.src = window.URL.createObjectURL(new Blob([resp.data], {type: "image/jpeg"}));
                setCounter(counter + 1);
            } else {
                enqueueSnackbar(translate({id: 'frame.noPages'}), {variant: 'info'});
            }
        })
            .catch((e) => console.log(e))
            .finally(() => setLoadingOverlayVisible(false));
    };

    useEffect(() => {
        if (pageImageAddress !== null) getPageImage();
    }, [pageImageAddress]);

    // useEffect(() => pageId && releasePageLock(releasePageLockTypes.page, pageId), [pageId]);

    useEffect(() => {
        if (countryChangeAllowed) {
            getNextPage();
        }
    }, [countryMarket]);

    const fitPageToWindow = () => {
        const width = imgRef.current.naturalWidth;
        const height = imgRef.current.naturalHeight;

        const parent = imgRef.current.parentElement;
        const parentW = parent.offsetWidth;
        const parentH = parent.offsetHeight;

        const hRatio = parentW / width;
        const vRatio = parentH / height;
        const ratio = Math.min(hRatio, vRatio);

        canvas.setViewportTransform([1, 0, 0, 1, 0, 0]);
        canvas.zoomToPoint({ x: 0, y: 0 }, ratio);
        const centerShift_x = (parentW - width * ratio) / 2;
        const centerShift_y = (parentH - height * ratio) / 2;
        const vpt: number[] = canvas.viewportTransform;
        vpt[4] += centerShift_x;
        vpt[5] += centerShift_y;
        canvas.setViewportTransform(vpt);
    };

    useEffect(() => {
        const canChangeCountry = getURLParam('canChangeCountry');
        if (canChangeCountry) {
            setCountryChangeAllowed(true);
        }
        if (counter > 0) {
            setTimeout(() => {
                const width = imgRef.current.naturalWidth;
                const height = imgRef.current.naturalHeight;
                const imgInstance = buildImageObject(imgRef.current, width, height);
                canvas.setBackgroundImage(imgInstance);
                canvas.setWidth(width);
                canvas.setHeight(height);

                fitPageToWindow();

                drawFrameProposals();
            }, 100)
        }
    }, [counter]);

    const submitFrames = () => {
        const objs = canvas.getObjects();
        if (objs.length > 0) {
            setLoadingOverlayVisible(true);
            const framesToSubmit: framesPerPagePerLeafletPayloadModel = objs.map((item) => {
                const newFrame: basicFramePayloadObject = {
                    x1: item.left,
                    y1: item.top,
                    x2: item.left + item.width,
                    y2: item.top + item.height,
                    validFrom: item.validFrom,
                    validTo: item.validTo
                };
                if (item.frameId) { // external frame
                    newFrame.id = item.frameId;
                }
                return newFrame;
            });
            axios.post(API_FRAMES_PER_PAGE_PER_LEAFLET(pageImageAddress.leafletId, pageImageAddress.pageNumber), framesToSubmit)
                .then(() => {
                    enqueueSnackbar(translate({id: 'frame.succ'}), {variant: 'success'});
                    pageId && releasePageLock(releasePageLockTypes.page, pageId);
                    cleanup();
                    cleanDetailsDrawerValues();
                    getPage();
                })
                .catch((e) => {
                    console.log(e);
                    enqueueSnackbar(translate({id: 'frame.err'}), {variant: 'error'});
                    setLoadingOverlayVisible(false);
                });
        } else {
            setConfirmDialogOpen(true);
        }
    };

    const submitEmptyPage = () => {
        axios.post(API_FRAMES_PER_PAGE_PER_LEAFLET(pageImageAddress.leafletId, pageImageAddress.pageNumber), [])
            .then(() => {
                enqueueSnackbar(translate({id: 'frame.succ'}), {variant: 'success'});
                pageId && releasePageLock(releasePageLockTypes.page, pageId);
                cleanup();
                cleanDetailsDrawerValues();
                getPage();
            })
            .catch((e) => {
                console.log(e);
                enqueueSnackbar(translate({id: 'frame.err'}), {variant: 'error'});
                setLoadingOverlayVisible(false);
            });
    };

    const sanitizeFrame = (shape, canvasWidth, canvasHeight) => {
        let cropL, cropR, cropT, cropB = false;
        if (shape.left < 0 ) cropL = true; // outside, left
        if (shape.top < 0 ) cropT = true; // outside, top
        if (shape.top + shape.height > canvasHeight ) cropB = true; // outside, bottom
        if (shape.left + shape.width > canvasWidth ) cropR = true; //outside, right

        if (cropL) shape.set({left: 0, width: shape.width + shape.left}); // shape.left is negative
        if (cropT) shape.set({top: 0, height: shape.height + shape.top}); //shape.top is negative
        if (cropR) shape.set({width: canvasWidth - shape.left});
        if (cropB) shape.set({height: canvasHeight - shape.top});

        if (shape.width < MIN_FRAME_WIDTH || shape.height < MIN_FRAME_HEIGHT) {
            shape.set({width: -1});
            return true;
        }
        return false;
    };

    const cleanDetailsDrawerValues = () => {
        setCurrentSelectedFrame(null);
        setCurrentSelectedFrameValidF(null);
        setCurrentSelectedFrameValidT(null);
    }

    useEffect(() => {
        // initialize canvas start
        const fabricCanvas = new fabric.Canvas('canvasFramingId', {fireRightClick: true, stopContextMenu: true, uniScaleKey: 'ctrlKey'});
        setCanvas(fabricCanvas);

        if(fabricCanvas === null) {
            return;
        }
        let origX: number = 0;
        let origY: number = 0;
        let isDown: boolean = false;
        let isDragging: boolean  = false;
        let lastPosX: number = 0;
        let lastPosY: number = 0;
        let rect = null;

        fabricCanvas.on('mouse:down', function(o){
            cleanDetailsDrawerValues();
            const {e} = o;

            if (e.altKey === true || e.button === 2) {
                isDown = true;
                isDragging = true;
                fabricCanvas.isDragging = true;
                fabricCanvas.selection = false;
                lastPosX = e.clientX;
                lastPosY = e.clientY;
            } else if (e.ctrlKey === true && fabricCanvas.findTarget(o) !== undefined) {
                setCurrentSelectedFrame(fabricCanvas.findTarget(o).uuid);
                setCurrentSelectedFrameValidF(fabricCanvas.findTarget(o).validFrom);
                setCurrentSelectedFrameValidT(fabricCanvas.findTarget(o).validTo);
            } else if (e.ctrlKey === false || fabricCanvas.findTarget(o) === undefined ) {
                const uniqueKey: string = uuidv4();
                if (fabricCanvas.findTarget(o)) {
                    const a = fabricCanvas.findTarget(o);
                    setTimeout( () => {a.lockMovementX  = true; a.lockMovementY  = true;}, 0)
                }
                fabricCanvas.discardActiveObject();
                fabricCanvas.selection = false;
                isDown = true;
                let pointer = fabricCanvas.getPointer(o.e);
                origX = pointer.x;
                origY = pointer.y;
                pointer = fabricCanvas.getPointer(o.e);
                rect = new fabric.Rect({
                    left: origX,
                    top: origY,
                    originX: 'left',
                    originY: 'top',
                    width: pointer.x-origX,
                    height: pointer.y-origY,
                    angle: 0,
                    // fill: 'rgba(255,0,0,0.5)',
                    fill: colorPicker.current,
                    stroke: complementaryColor.current,
                    strokeWidth: 1,
                    transparentCorners: false
                });
                rect.uuid = uniqueKey;
                rect.validFrom = null;
                rect.validTo = null;
                rect.controls = getControls();
                fabricCanvas.add(rect);
            }
        });

        fabricCanvas.on('mouse:move', function(o){
            if (!isDown) return;
            if (isDragging) {
                const e = o.e;
                const vpt = fabricCanvas.viewportTransform;
                vpt[4] += e.clientX - lastPosX;
                vpt[5] += e.clientY - lastPosY;
                fabricCanvas.requestRenderAll();
                lastPosX = e.clientX;
                lastPosY = e.clientY;
            } else {
                const pointer = fabricCanvas.getPointer(o.e);

                if (origX > pointer.x) {
                    rect.set({left: pointer.x});
                }

                if (origY > pointer.y) {
                    rect.set({top: pointer.y});
                }

                rect.set({ width: Math.abs(origX - pointer.x) });
                rect.set({ height: Math.abs(origY - pointer.y) });
                fabricCanvas.renderAll();
            }
        });

        fabricCanvas.on('object:moved', function(o) {
            const shape = o.target;
            const {width, height} = fabricCanvas;
            sanitizeFrame(shape, width, height);
        })

        fabricCanvas.on('object:scaled', function(o) {
            // could do that in 'object:scaling' event but then rendering gets glitchy, fabricCanvas.renderAll() helps a bit but still looks weird
            // good hax, i dont want to scale items but actually resize frames for our use case
            // otherwise lot of other simple things get overcomplicated: need to recalculate frame size before saving, recalculate for size and position validation etc
            const shape = o.target;
            shape.set({
                width: shape.scaleX * shape.width,
                height: shape.scaleY * shape.height,
                scaleX: 1,
                scaleY: 1
            });
            const {width, height} = fabricCanvas;
            sanitizeFrame(shape, width, height);
        });

        fabricCanvas.on('mouse:up', function(){
            if (!isDragging) {
                const frames = fabricCanvas.getObjects();
                frames.forEach((item) => {
                    if (frameTooSmall(item)) {
                        fabricCanvas.remove(item);
                    } else {
                        item.lockMovementX = false;
                        item.lockMovementY = false;
                        item.left = Math.round(item.left);
                        item.top = Math.round(item.top);
                        item.width = Math.round(item.width);
                        item.height = Math.round(item.height);
                    }
                });
                if (frames.length > 0) {
                    const newObject = frames[frames.length-1];
                    const {width, height} = fabricCanvas;
                    if (sanitizeFrame (newObject, width, height)) {
                        fabricCanvas.remove(newObject);
                    }
                }
            }
            isDown = false;
            this.setViewportTransform(this.viewportTransform);
            isDragging = false;
            fabricCanvas.isDragging = false;
            fabricCanvas.selection = true;
        });

        fabricCanvas.on('mouse:wheel', function(o) {
            const {e} = o;
            const delta = e.deltaY;
            let zoom = fabricCanvas.getZoom();
            zoom *= 0.999 ** delta;
            if (zoom > 20) zoom = 20;
            if (zoom < 0.01) zoom = 0.01;
            // just a zoom:
            // fabricCanvas.setZoom(zoom);
            // zoom to cursor:
            fabricCanvas.zoomToPoint({ x: e.offsetX, y: e.offsetY }, zoom);
            e.preventDefault();
            e.stopPropagation();
        });

        setCanvasReady(true);
        // initialize canvas end
    }, []);

    useEffect(() => {
        if (canvasReady) {
            const objs = canvas.getObjects();
            const strokeColor: string = getComplementaryColor(frameFill);
            if (objs) {
                objs.forEach((frame) => {
                    frame.set('fill', frameFill);
                    frame.set('stroke', strokeColor);
                });
                setTimeout( () => canvas.renderAll(), 0);
            }
            colorPicker.current = frameFill;
            complementaryColor.current = strokeColor;
        }
    }, [canvasReady, frameFill]);

    useEffect(() => {
        if(canvasReady) {
            getPage();
        }
    }, [canvasReady]);

    const getPage = () => getNextPage(getURLParam('leafletId'));

    const deleteSelectedFrame = () => {
        cleanDetailsDrawerValues();
        const selected = canvas.getActiveObjects();
        canvas.remove(...selected);
        canvas.discardActiveObject().renderAll();
    };

    const deleteAllFrames = () => {
        cleanDetailsDrawerValues();
        canvas.remove(...canvas.getObjects());
    }

    const handleSkipPage = () => {
        cleanDetailsDrawerValues();
        setLoadingOverlayVisible(true);
        axios.put(API_SKIP_PAGE(pageImageAddress.leafletId, pageImageAddress.pageNumber))
            .then(() => {
                pageId && releasePageLock(releasePageLockTypes.page, pageId);
                cleanup();
                getPage();
                enqueueSnackbar(`${translate({id: 'frame.skipped'})}`, {variant: 'info', persist: false});
            })
            .catch((e) =>  {
                console.log(e)
                enqueueSnackbar(`${translate({id: 'a.error2'})}`, {variant: 'error', persist: false});
                setLoadingOverlayVisible(false);
            })
    }

    const handleFixPreviousPage = () => {
        pageId && releasePageLock(releasePageLockTypes.page, pageId);
        history.push(`${paths.fixReportedFrames}?id=${pageImageAddress.leafletId}&pageNumber=${pageImageAddress.pageNumber-1}&from=${paths.framing}`);
    }

    const handleKeyPress = (e) => {
        if (e.key === 'Delete') {
            deleteSelectedFrame();
        }
    };

    const handleColorChange = (value:string) => {
        setFrameFill(value);
        colorPicker.current = value;
        complementaryColor.current = getComplementaryColor(value);
    };

    const handleChangeDetailsDrawer = (internalFrameId: string, validFrom: string, validTo: string) => {
        const objs = canvas.getObjects();
        objs.forEach(object => {
            if (object.uuid && object.uuid === internalFrameId) {
                object.validFrom = validFrom;
                object.validTo = validTo;
            }
        });
        setCurrentSelectedFrameValidF(validFrom);
        setCurrentSelectedFrameValidT(validTo);
    };

    const goHome = () => {
        pageId && releasePageLock(releasePageLockTypes.page, pageId);
        history.push(paths.home);
    }

    return (
        <div className="framingContainerRoot" onKeyDown={handleKeyPress} tabIndex={-1}>
            <div className="pageContainer">
                <canvas ref={canvasRef} width="500px" height="500px" id='canvasFramingId'/>
                <img ref={imgRef} src="" alt="" style={{display: 'none'}} id="canvasSourceImageElement"/>
            </div>
            <LoadingOverlay show={loadingOverlayVisible}/>
            <div className="frameActionsContainer">
                <div>
                    <NavigationMenuButton />
                </div>
                <div>
                    <ColorPicker value={frameFill} onChange={handleColorChange}/>
                    <Tooltip title={translate({id: 'frame.reviseFramesPrevPage'})}>
                        <IconButton color="primary"
                                    disabled={pageImageAddress?.pageNumber<=1}
                                    onClick={handleFixPreviousPage}>
                            <KeyboardArrowLeft/> 
                        </IconButton>
                    </Tooltip>
                    <Button onClick={handleSkipPage} color="primary" variant="outlined">{translate({id: 'frame.skip'})}</Button>
                    <Button onClick={deleteAllFrames}>{translate({id: 'frame.clear'})}</Button>
                    {countryChangeAllowed && <CountrySelectorConnected size={inputSize.small}/>}
                    <FormControlLabel className="showLeafletCheckbox"
                                      control={<Checkbox tabIndex={-1}
                                                         checked={isLeafletBrowserDrawerOpen}
                                                         onChange={(e) => setIsLeafletBrowserDrawerOpen(e.target.checked)}/>}
                                                         label={translate({id: 'nav.showLeaflet'})}
                    />
                    <FormControlLabel control={<Checkbox tabIndex={-1}
                                      checked={isDetailsBrowserDrawerOpen}
                                      onChange={(e) => setIsDetailsBrowserDrawerOpen(e.target.checked)}/>}
                                      label={translate({id: 'a.details'})}
                    />
                    <Button onClick={fitPageToWindow}>{translate({id: 'frame.fitToWindow'})}</Button>
                </div>
                <div>
                    <Button onClick={submitFrames} color="primary" variant="contained">{translate({id: 'frame.submit'})}</Button>
                </div>
                <div>
                    <Button onClick={submitEmptyPage} color="primary" variant="outlined" size="small">{translate({id: 'frame.empty'})}</Button>
                </div>
                <div>
                    <ButtonClose onClick={goHome}/>
                </div>
            </div>
            <LeafletBrowserDrawer isOpen={isLeafletBrowserDrawerOpen}
                                  leafletId={pageImageAddress?.leafletId}
                                  pageNumber={pageImageAddress?.pageNumber}/>
            <DetailsDrawer isOpen={isDetailsBrowserDrawerOpen}
                           internalFrameId={currentSelectedFrame}
                           onChange={handleChangeDetailsDrawer}
                           validFrom={currentSelectedFrameValidF}
                           validTo={currentSelectedFrameValidT}/>
            <ConfirmDialog open={confirmDialogOpen}
                           message={translate({id: 'frame.emptyConfirm'})}
                           onConfirm={() => {
                               submitEmptyPage();
                               setConfirmDialogOpen(false);
                           }}
                           confirmLabelId="a.yes"
                           cancelLabelId="a.no"
                           onCancel={() => setConfirmDialogOpen(false)}
            />
        </div>
    );
};

export default Framing;