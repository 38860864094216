import './AdvertisementBrowser.scss';
/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useState} from 'react';
import {FormattedMessage} from 'react-intl';
import {Button} from '@mui/material';
import {PaperX} from 'components/PaperX';
import {paths} from 'paths';
import {
    API_GET_FRAMES_FOR_LEAFLET,
    API_STATUSES,
    API_LEAFLET_MANAGEMENT_LEAFLETS,
    API_GET_IF_PAGE_DUPLICATED
} from 'config/api/constants';
import {leafletModel, pageValidityBE} from 'shared/models/leaflet.model';
import {frame, framesPageNumberOffset} from 'shared/models/frame.model';
import {promotionUI} from 'shared/models/promotion.model';
import {leafletSummary} from 'shared/models/leaflet.model';
import {isPageConnectedResponseModel} from 'shared/models/page.model';
import {validity} from 'shared/models/validity.model';
import {useApi} from 'utils/axiosHooks/axiosHooks';
import {getId, getURLParam, getURLParamAsInt} from 'utils/routing';
import {releasePageLock, releasePageLockTypes} from 'utils/lockUtils';
import { getFromLocalStorage, storeInLocalStorage } from 'utils/storageUtils';
import {useFormatMessage} from 'utils/translate';
import Footer from 'components/Footer';
import ButtonClose from 'components/Buttons/ButtonClose';
import {LeafletBrowser} from 'components/gfx';
import {LoadingOverlay} from 'components/LoadingOverlay';
import {arePromotionsValid} from 'modules/Advertisement/FrameDescription/FrameDescriptionMVP';
import FrameValidity from 'modules/Advertisement/FrameValidity/FrameValidity';
import EditFrameValidity from './EditFrameValidity';
import LeafletSummary, {buildLeafletSummary} from 'components/LeafletSummary/LeafletSummary';
import {usePutPromotionsForFrame} from 'modules/Advertisement/AdvertisementsOverview/advertisementsOverviewAPI';
import AdvertisementBrowserSummary from './AdvertisementBrowserSummary';
import AdvertisementBrowserEditFramePromotions, { modes } from './AdvertisementBrowserEditFramePromotions';
import DisconnectPage from './DisconnectPage';
import DeleteFrame from './DeleteFrame';

export const leafletDataTemplate = {
    country: '',
    leafletId: '',
    metaData: null,
    headerData: null
};

const localStorageKeyViewMode: string = 'viewMode_advertisementBrowser';

const buildPageData = (leafletData: leafletModel, currentPage: number): pageValidityBE => {
    return {
        pageNumber: currentPage,
        validFrom: leafletData.headerData?.pageValidities.find(item => item.pageNumber === currentPage)?.validFrom || null,
        validTo: leafletData.headerData?.pageValidities.find(item => item.pageNumber === currentPage)?.validTo || null
    }
}

const AdvertisementBrowser = ({history}) => {
    const translate = useFormatMessage();

    const [leafletData, setLeafletData] = useState<leafletModel>(leafletDataTemplate);
    const [framesData, setFramesData] = useState<frame[]>([]);
    const [selectedFrameId, setSelectedFrameId] = useState<string>('');
    const [isFrameModified, setIsFrameModified] = useState<boolean>(false);
    const [isTableMode, setIsTableMode] = useState<boolean>(true);
    const [saveValidFrom, setSaveValidFrom] = useState<string>(null);
    const [saveValidTo, setSaveValidTo] = useState<string>(null);
    const [currentPage, setCurrentPage] = useState<number>(getURLParamAsInt('page') || 1);
    const [currentId, setCurrentId] = useState<string>(getId());
    const [selectedFramePromotions, setSelectedFramePromotions] = useState<promotionUI[]>(null);
    const [promotions2save, setPromotions2save] = useState<promotionUI[]>([]);
    const [isFirstLoad, setIsFirstLoad] = useState<boolean>(false);
    const [givenFrameValidity, setGivenFrameValidity] = useState<validity>({validFrom: '', validTo: ''});
    const [showFrameValidity, setShowFrameValidity] = useState<validity>({validFrom: '', validTo: ''});
    const [leafletSummary, setLeafletSummary] = useState<leafletSummary>(null);
    const [isPageConnected, setIsPageConnected] = useState<boolean>(false);
    const [shouldAdvertisementBrowserReload, setShouldAdvertisementBrowserReload] = useState<boolean>(false);
    const [isPromotionsSuggestionsOpen, setIsPromotionsSuggestionsOpen] = useState<boolean>(false);

    const leafletDetailsAPIGet = useApi('get', {}, {errMsg: 'a.error2'} );
    const framesAPIGet = useApi('get', [], {errMsg: 'frameDescription.err'} );
    const pageDuplicatedAPIGet = useApi('get', {}, {errMsg: 'advertisementsOverview.getIfDuplicatesErr'} );

    const [newProductDrawerOpen, setNewProductDrawerOpen] = useState<boolean>(false);
    const [newBPCODrawerOpen, setNewBPCODrawerOpen] = useState<boolean>(false);

    const [mode, setMode] = useState<modes>(() =>
      getFromLocalStorage(localStorageKeyViewMode) === modes.TABLE
        ? modes.TABLE
        : modes.DETAILS
    );

    const toggleModes = () => {
        if (mode === modes.DETAILS) {
            setMode(modes.TABLE);
            storeInLocalStorage(localStorageKeyViewMode, modes.TABLE);
        } else {
            setMode(modes.DETAILS);
            storeInLocalStorage(localStorageKeyViewMode, modes.DETAILS);
        }
    };

    const handleUpdateSuccess = () => {
        setIsTableMode(true);
        releasePageLock(releasePageLockTypes.frame, selectedFrameId);
    }

    const handleGetOrUpdate404 = () => {
        setIsTableMode(true);
        setShouldAdvertisementBrowserReload(true);
        framesAPIGet.call(API_GET_FRAMES_FOR_LEAFLET(leafletData.leafletId));
        setSelectedFrameId('')
        releasePageLock(releasePageLockTypes.frame, selectedFrameId);
    }

    const handleDisconnectPageSuccess = () => {
        setIsPageConnected(false);
        setShouldAdvertisementBrowserReload(true);
        framesAPIGet.call(API_GET_FRAMES_FOR_LEAFLET(leafletData.leafletId));
    }

    const updatePromotionsAPI = usePutPromotionsForFrame(selectedFrameId, promotions2save, saveValidFrom, saveValidTo, handleUpdateSuccess, handleGetOrUpdate404);

    useEffect(() => {
        const id: string = getId();
        setCurrentId(id);
        if (id) {
            setLeafletData({...leafletDataTemplate, leafletId: id});
            leafletDetailsAPIGet.call(`${API_LEAFLET_MANAGEMENT_LEAFLETS}/${id}`);
            framesAPIGet.call(API_GET_FRAMES_FOR_LEAFLET(id));
        }
    }, []);

    useEffect(() => {
        if (isFirstLoad){
            setShowFrameValidity(givenFrameValidity);
            setSaveValidFrom(givenFrameValidity.validFrom);
            setSaveValidTo(givenFrameValidity.validTo);
            setIsFirstLoad(false);
        }
    },[givenFrameValidity, isFirstLoad])

    useEffect(() => {
        if (leafletDetailsAPIGet.status === API_STATUSES.IDLE) {
            const newLeafletData: leafletModel = {...leafletData};
            newLeafletData.country = leafletDetailsAPIGet.data.country;
            newLeafletData.metaData = leafletDetailsAPIGet.data.metaData;
            newLeafletData.headerData = leafletDetailsAPIGet.data.headerData;
            setLeafletData(newLeafletData);
            setLeafletSummary(buildLeafletSummary(newLeafletData.headerData, newLeafletData.metaData, buildPageData(newLeafletData, currentPage)));
        }
    }, [leafletDetailsAPIGet.status]);

    useEffect(() => {
        if (leafletData.metaData && framesAPIGet.data?.length > 0) {
            const page: string = getURLParam('page');
            const frameId: string = getURLParam('frameId');
            if (page && frameId) {
                handlePageChange(parseInt(page), frameId);
            }
        }
    }, [leafletData, framesAPIGet.data]);

    useEffect(() => {
        if (currentPage && currentId){
           pageDuplicatedAPIGet.call(API_GET_IF_PAGE_DUPLICATED(currentId, currentPage));
        }
    },[currentPage, currentId]);

    useEffect(() => {
        if (pageDuplicatedAPIGet.status === API_STATUSES.IDLE){
            const response: isPageConnectedResponseModel = pageDuplicatedAPIGet.data;
            setIsPageConnected(response.connected);
        }
    },[pageDuplicatedAPIGet.status]);

    useEffect(() => {
        if (framesAPIGet.data) {
            setFramesData(framesPageNumberOffset(framesAPIGet.data));
        }
    }, [framesAPIGet.data]);

    useEffect(() => {
        if (leafletSummary) {
            setLeafletSummary(buildLeafletSummary(leafletData.headerData, leafletData.metaData, buildPageData(leafletData, currentPage)));
        }
    }, [currentPage]);

    useEffect(() => {
        if (selectedFrameId) {
            setPromotions2save([]);
            setIsFrameModified(false);
            setIsFirstLoad(true);
        }
    }, [selectedFrameId]);

    useEffect(() => {
        if (isPromotionsSuggestionsOpen) setIsPromotionsSuggestionsOpen(false);
    }, [isPromotionsSuggestionsOpen]);

    const handleFrameClick = (frameId: string) => setSelectedFrameId(frameId);

    const handleModifyPromotions = (modifiedPromotions:promotionUI[]) => {
        setPromotions2save(modifiedPromotions);
        setIsFrameModified(true);
    };

    const prepareToSubmitValidity = () => {
        if (selectedFramePromotions !== null){
            setPromotions2save(selectedFramePromotions);
            setIsFrameModified(true);
        }
    };

    const handleSubmit = () => {
        updatePromotionsAPI.mutate();
        setShowFrameValidity({validFrom: saveValidFrom, validTo: saveValidTo});
    }

    const canSave = (): boolean => isFrameModified && arePromotionsValid(promotions2save);

    const handlePageChange = (pageNumber: number, selectedFrame?: string) => {
        const from = getURLParam("from");
        if (
            from === paths.productsList ||
            from === paths.reportedFramesBrowser ||
            from === paths.reportedIssues
        ) {
            setCurrentPage(pageNumber);
        } else {
            setCurrentPage(pageNumber + 1);
        }
        setSelectedFrameId(selectedFrame || '');
        setShouldAdvertisementBrowserReload(false);
    };

    const handleCloseEditBtn = () => {
        setIsFrameModified(false);
        setIsTableMode(true);
        setSaveValidFrom(givenFrameValidity.validFrom);
        setSaveValidTo(givenFrameValidity.validTo);
        setShowFrameValidity(givenFrameValidity);
        releasePageLock(releasePageLockTypes.frame, selectedFrameId);
    };

    const handleDeleteValidity = () => {
        setSaveValidFrom(null);
        setSaveValidTo(null);
        prepareToSubmitValidity();
    }

    const handleValidityChange = (validity: validity) => {
        setSaveValidFrom(validity.validFrom);
        setSaveValidTo(validity.validTo);
        prepareToSubmitValidity();
    };

    const openNewProductDrawer = () => setNewProductDrawerOpen(true);
    const openNewBPCODrawer = () => setNewBPCODrawerOpen(true);

    const onDeletedSelectedFrame = () => {
        setShouldAdvertisementBrowserReload(true);
        framesAPIGet.call(API_GET_FRAMES_FOR_LEAFLET(leafletData.leafletId));
        setSelectedFrameId('');
    }

    const goBack = () => {
        const from: string = getURLParam('from');
        const relatedPromoId: string = getURLParam('relatedPromoId');
        if ( from  === paths.reportedFramesBrowser || from === paths.reportedAdvertisementsBrowser) {
            history.push(`${from}?id=${leafletData.leafletId}`);
        } if (from === paths.advertisementsOverviewPromotions && relatedPromoId) {
            history.push(`${from}?id=${relatedPromoId}`);
        } else history.push(from || paths.advertisementsOverview);
    };

    return (
        <div className="viewRoot advertisementBrowserRoot">
            <div className="viewport">
                <LoadingOverlay show={leafletDetailsAPIGet.status === API_STATUSES.PENDING || framesAPIGet.status === API_STATUSES.PENDING || updatePromotionsAPI.isLoading}/>
                <div className="viewContainer _directionRow">
                    <div className='_directionCol leafletContainer'>
                        <div className="_growRelative">
                            <div className="_fillRelative">
                                <PaperX className="_fullHeight">
                                    <LeafletBrowser leaflet={leafletData}
                                                    frames={framesData}
                                                    showFileName
                                                    onFrameClick={(frameId) => handleFrameClick(frameId)}
                                                    onPageChange={(pageNumber) => handlePageChange(pageNumber)}
                                                    externalPageNumber={currentPage-1}
                                                    frame2highlight={selectedFrameId}
                                                    isDisabled={!isTableMode}
                                    />
                                </PaperX>
                            </div>
                        </div>
                        <PaperX>
                            <LeafletSummary data={leafletSummary} frameValidity={givenFrameValidity.validFrom}/>
                            {isTableMode ?
                                <>
                                    {selectedFrameId ? <FrameValidity frameValidity={showFrameValidity}/>
                                        :
                                        <span><FormattedMessage id="frame.validity"/>:</span>
                                    }
                                </>
                                :
                                <EditFrameValidity disableDelete={!(saveValidFrom && saveValidTo)}
                                                   frameValidity={{validFrom: saveValidFrom, validTo: saveValidTo}}
                                                   onSave={(frameValidity) => handleValidityChange(frameValidity)}
                                                   onDelete={handleDeleteValidity}
                                />
                            }
                        </PaperX>
                    </div>
                    <div className="_directionCol advertisementsContainer">
                        {isTableMode
                        ?
                            <AdvertisementBrowserSummary selectedFrameId={selectedFrameId}
                                                         currentLeafletId={currentId}
                                                         currentPage={currentPage}
                                                         onValidityForSelectedFrameIdChange={(frameValidity) => frameValidity ? setGivenFrameValidity(frameValidity) : null}
                                                         reload={shouldAdvertisementBrowserReload}
                            />
                        :
                            <AdvertisementBrowserEditFramePromotions leafletData={leafletData}
                                                                     selectedFrameId={selectedFrameId}
                                                                     newProductDrawerOpen={newProductDrawerOpen}
                                                                     newBPCODrawerOpen={newBPCODrawerOpen}
                                                                     on404={handleGetOrUpdate404}
                                                                     onProductDrawerClose={() => setNewProductDrawerOpen(false)}
                                                                     onBPCODrawerClose={() => setNewBPCODrawerOpen(false)}
                                                                     isPromotionsSuggestionsOpen={isPromotionsSuggestionsOpen}
                                                                     onPromotionsModified={handleModifyPromotions}
                                                                     onPromotionsNotModified={setSelectedFramePromotions}
                                                                     currentPage={currentPage}
                                                                     mode={mode}
                            />
                        }
                    </div>
                </div>
            </div>
            <Footer
                actionsLeft={ isTableMode && <>
                    <DisconnectPage isPageConnected={isPageConnected}
                                    leafletId={leafletData.leafletId}
                                    onPageDisconnected={handleDisconnectPageSuccess}
                                    page={currentPage}/>
                    <DeleteFrame frameId={selectedFrameId}
                                 on404={handleGetOrUpdate404}
                                 onFrameDeleted={onDeletedSelectedFrame}/>
                </>
                }
                actionsRight={
                    isTableMode
                    ?
                        <>
                            <Button variant="outlined" color="primary" onClick={() => setIsTableMode(false)} disabled={!selectedFrameId}>
                                <FormattedMessage id="a.edit"/>
                            </Button>
                            <ButtonClose onClick={goBack}/>
                        </>
                    :
                        <>
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={() => setIsPromotionsSuggestionsOpen(true)}
                                disabled={!(leafletData.country && selectedFrameId)}
                            >
                                {translate({ id: "frameDescription.fetchProdSugTitle" })}
                            </Button>
                            <Button variant="outlined" color="primary" onClick={() => toggleModes()}>{translate({id: `${mode === modes.DETAILS ? 'a.summary' : 'a.details'}`})}</Button>
                            <Button variant="contained" color="primary" onClick={handleSubmit} disabled={!canSave()}>
                                <FormattedMessage id="a.submit"/>
                            </Button>
                            <Button variant="outlined" color="primary" tabIndex={-1} onClick={openNewProductDrawer}>{translate({id: 'productSearch.createProduct'})}</Button>
                            <Button variant="outlined" color="primary" tabIndex={-1} onClick={openNewBPCODrawer}>{translate({id: 'productSearch.createBPCO'})}</Button>
                            <Button variant="outlined" color="primary" onClick={handleCloseEditBtn}>
                                <FormattedMessage id="a.cancel"/>
                            </Button>
                        </>
                    }
            />
        </div>
    );
}

export default AdvertisementBrowser;
