// receive msg
export const pageLockCurrentFrame: string = '/lost-lock';

export const pageLockCurrentPage: string = '/lost-lock/currentPageResponse';

// send msg
export const sendCurrentFrameDest: string = '/web-socket/page-lock-management/currentFrame'; // [payload: frameId] for describeFrame, advertisementBrowser view

export const sendCurrentPageDest: string = '/web-socket/page-lock-management/currentPage'; // [payload: pageId] for framing, fixreportedissues viewx

